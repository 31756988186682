import { useEffect, useState } from "react";
import "./ActivityProgressBar.css";
import { ActivityIcon } from "./ActivityIcon";
import { ClickableIcon } from "./ClickableIcon";
import { X } from "@phosphor-icons/react";

export const ActivityProgressBar = ({ icon, title, points, total, onClose }) => {
    const [percentFed, setPercentFed] = useState(null);

    useEffect(() => {
        setPercentFed(((points / total) * 100).toFixed(0));
    }, [points]);

    return (
        <div className="ActivityProgressBarHolder">
            <ActivityIcon emoji={icon} />
            <div className="ActivityProgressBarContent">
                <div className="ActivityProgressBarTitle">
                    <span>
                        {title}{" "}
                        {points < total && (
                            <>
                                {" "}
                                - {points} / {total}
                            </>
                        )}
                    </span>
                    <ClickableIcon icon={<X weight="bold" />} size="large" onClick={onClose} />
                </div>

                <div className="ActivityProgressBar">
                    <div
                        className="ui tiny progress"
                        data-percent={`${percentFed}`}
                        style={{
                            top: "0.5rem",
                            flexGrow: "1",
                        }}>
                        {total === null || (percentFed && percentFed >= 100) ? (
                            <div />
                        ) : (
                            <div className="bar" style={{ width: `${percentFed}%` }}>
                                <div className="progress" style={{ marginTop: "-1rem" }}>
                                    <span
                                        style={{
                                            whiteSpace: "nowrap",
                                            float: "right",
                                        }}></span>
                                </div>
                            </div>
                        )}
                    </div>
                    <span className="ActivityProgressBar-CloseWrapper">
                        <ClickableIcon icon={<X weight="bold" />} size="large" onClick={onClose} />
                    </span>
                </div>
            </div>
        </div>
    );
};
